import React, { Component } from "react";
import classNames from "classnames/bind";
import PropTypes from "prop-types";

import styles from "./subTitle.module.sass";

let cx = classNames.bind(styles);

class SubTitle extends Component {
    render = () => {
        const { color, children, mb, mt, pt, transform } = this.props;
        let classes = cx({
            [color]: true,
            [transform]: transform,
        });

        return (
            <p
                className={classes}
                style={{
                    marginBottom: `${mb}px`,
                    marginTop: `${mt}px`,
                    paddingTop: `${pt}px`,
                }}
            >
                {children}
            </p>
        );
    };
}

SubTitle.propTypes = {
    pt: PropTypes.number,
    mt: PropTypes.number,
    mb: PropTypes.number,
    transform: PropTypes.boolean,
};

SubTitle.defaultProps = {
    pt: 0,
    mt: 0,
    mb: 0,
    transform: "",
};

export default SubTitle;
