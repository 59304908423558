import React, { useContext, useEffect } from "react";
import SiteLayout from "../components/SiteLayout";
import Seo from "../components/Seo";
import Breadcrumb from "../components/BreadCrumb";
import Block from "../components/Block";
import Title from "../components/Title";
import SadSpendingBox from "../components/Eshop/SadSpendingBox";
import { graphql } from "gatsby";
import { DEFAULT_EMITENT, DEFAULT_STOCK } from "../constants";
import { checkoutContext } from "../context/checkoutContextProvider";
import EshopProducts from "../components/EshopProducts";
import slugify from "slugify";

const EshopCategory = props => {
    const {
        location,
        uri,
        pageContext: {
            id,
            name,
            description,
            priceMin,
            priceMax,
            parent: parentCategory,
            page,
            categorySlug,
            filterConfig,
        },
        data: {
            allCmCategory: { nodes },
        },
    } = props;

    const { products } = nodes[0];

    const { setFilter, filter } = useContext(checkoutContext);

    const crumbs = [
        {
            label: "E-shop",
            link: "/e-shop",
        },
    ];

    if (parentCategory) {
        crumbs.push({
            label: parentCategory.name,
            link: `/e-shop/${parentCategory.slug}`,
        });
    }

    crumbs.push({
        label: name,
        link: uri,
    });

    let emitentTypes = [];

    products.map(({ emitent }) => {
        emitentTypes.map(({ label }) => label).indexOf(emitent) === -1 &&
            emitent !== "" &&
            emitentTypes.push({
                label: emitent,
                slug: slugify(emitent, { lower: true }),
            });
    });

    let startDate = new Date("5000/12/31");
    let endDate = new Date(0);

    products.map(({ dateOfIssue }) => {
        if (dateOfIssue) {
            const tempDate = new Date(dateOfIssue);

            if (tempDate < startDate) {
                startDate = tempDate;
            }

            if (tempDate > endDate) {
                endDate = tempDate;
            }
        }
    });

    products.map(product => {
        product.priceToShow = product.specialPrice
            ? product.specialPrice
            : product.priceVat;
    });

    let showInStock = DEFAULT_STOCK;

    useEffect(() => {
        if (!filter || !filter[`${id}-priceMin`]) {
            setFilter({
                [`${id}-emitent`]: DEFAULT_EMITENT,
                [`${id}-priceMin`]: priceMin,
                [`${id}-priceMax`]: priceMax,
                [`${id}-rangeBottom`]: priceMin,
                [`${id}-rangeTop`]: priceMax,
                [`${id}-startDate`]: startDate,
                [`${id}-showInStock`]: showInStock,
                [`${id}-endDate`]: endDate,
            });
        }
    }, []);

    return (
        <SiteLayout location={location} eshop>
            <Seo title={`${name}`} description={description} url={uri} />
            <Breadcrumb crumbs={crumbs} />

            <Block leftIndent>
                <Title type={"h1"} mb={50} mt={30}>
                    {name}
                </Title>
            </Block>

            <EshopProducts
                categoryId={id}
                categorySlug={categorySlug}
                emitentTypes={emitentTypes}
                priceMin={priceMin}
                priceMax={priceMax}
                startDateOfIssue={startDate}
                endDateOfIssue={endDate}
                allProducts={products}
                page={page}
                filterData={filter}
                filterConfig={filterConfig}
            />

            <Block grey={true}>
                <SadSpendingBox
                    images={{
                        spender: props.data.spender.childImageSharp.fixed,
                    }}
                />
            </Block>
        </SiteLayout>
    );
};

export default EshopCategory;

export const pageQuery = graphql`
    query GET_ESHOP_CATEGORY($id: String!) {
        allCmCategory(filter: { id: { eq: $id } }) {
            nodes {
                products {
                    productName
                    priceVat
                    specialPrice
                    itemId
                    url
                    slug
                    dateOfIssue
                    emitent
                    category
                    stockState
                    vat
                    featuredImg {
                        childImageSharp {
                            fluid(
                                maxWidth: 318
                                quality: 100
                                traceSVG: { color: "#B29B5B" }
                            ) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            }
        }
        spender: file(relativePath: { eq: "spender.png" }) {
            childImageSharp {
                fixed(width: 388, quality: 100) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        }
    }
`;
