import React, { useState } from "react";
import onClickOutside from "react-onclickoutside";

import style from "./productsorter.module.sass";
import classNames from "classnames";

const ProductSorter = ({ selectedSorter, handleChange, disabled }) => {
    const [sorterOpen, setSorterOpen] = useState(false);

    let sortListClass = classNames({
        [style.sortList]: true,
        [style.sortListOpen]: sorterOpen,
    });

    ProductSorter.handleClickOutside = () => {
        setSorterOpen(false);
    };

    return (
        <div
            className={style.sortContainer}
            onClick={() => (disabled ? "" : setSorterOpen(!sorterOpen))}
        >
            <span>Od {selectedSorter.label}</span>
            <img
                src={require("../../images/icons_new/dropdown-arrow.svg")}
                alt=""
            />
            <ul className={sortListClass}>
                <li>
                    <label
                        htmlFor={"mostExpensive"}
                        className={
                            selectedSorter.slug === "priceDesc"
                                ? style.active
                                : undefined
                        }
                    >
                        <input
                            type={"radio"}
                            id={"mostExpensive"}
                            name={"sorter"}
                            value={"priceDesc"}
                            checked={selectedSorter.slug === "priceDesc"}
                            onChange={value => {
                                handleChange(value);
                                setSorterOpen(false);
                            }}
                        />
                        nejdražšího
                    </label>
                </li>
                <li>
                    <label
                        htmlFor={"cheapest"}
                        className={
                            selectedSorter.slug === "priceAsc"
                                ? style.active
                                : undefined
                        }
                    >
                        <input
                            type={"radio"}
                            id={"cheapest"}
                            name={"sorter"}
                            value={"priceAsc"}
                            checked={selectedSorter.slug === "priceAsc"}
                            onChange={value => {
                                handleChange(value);
                                setSorterOpen(false);
                            }}
                        />
                        nejlevnějšího
                    </label>
                </li>
            </ul>
        </div>
    );
};

ProductSorter.prototype = {};

const clickOutsideConfig = {
    handleClickOutside: () => ProductSorter.handleClickOutside,
};

export default onClickOutside(ProductSorter, clickOutsideConfig);
