import React, { useContext, useState } from "react";
import slugify from "slugify";
import Block from "../Block";
import Filter from "../Filter";
import Products from "../Products";
import { DEFAULT_EMITENT, IN_STOCK, SORT_TYPES } from "../../constants";
import SubTitle from "../SubTile";
import { checkoutContext } from "../../context/checkoutContextProvider";

import CoinSpinner from "./CoinSpinner";
import coinSpinnerStyle from "./coinSpinner.module.sass";

const findPriceMin = products =>
    Math.min.apply(
        Math,
        products.map(product => product.priceToShow)
    );

const findPriceMax = products =>
    Math.max.apply(
        Math,
        products.map(product => product.priceToShow)
    );

const EshopProducts = ({
    categoryId,
    categorySlug,
    emitentTypes,
    priceMin,
    priceMax,
    startDateOfIssue,
    endDateOfIssue,
    allProducts,
    page,
    filterData,
    filterConfig,
}) => {
    const getSmallPart = () => Math.ceil(((priceMax - priceMin) / 100) * 5);

    const getValueToSet = (
        typeToSet,
        rangeCompare,
        value,
        priceCompare,
        ignoreOffset
    ) => {
        if (typeToSet === "TOP") {
            if (value <= rangeCompare) {
                return rangeCompare + getSmallPart();
            } else if (value <= priceCompare && priceCompare - value > 50) {
                return value;
            } else {
                if (ignoreOffset) return value;
                return priceCompare;
            }
        } else {
            if (value >= rangeCompare) {
                return rangeCompare - getSmallPart();
            } else if (value >= priceCompare && value - priceCompare > 50) {
                return value;
            } else {
                if (ignoreOffset) return value;
                return priceCompare;
            }
        }
    };

    const { setFilter } = useContext(checkoutContext);

    const [pageSelected, setPageSelected] = useState(page - 1);
    const [helperPageSelected, setHelperPageSelected] = useState(pageSelected);

    const [sortType, setSortType] = useState(
        filterData[`${categoryId}-sortType`]
            ? filterData[`${categoryId}-sortType`]
            : SORT_TYPES[1]
    );

    const [emitent, setEmitent] = useState(
        filterData[`${categoryId}-emitent`]
            ? filterData[`${categoryId}-emitent`]
            : DEFAULT_EMITENT
    );

    const [startDate, setStartDate] = useState(
        filterData[`${categoryId}-startDate`]
            ? filterData[`${categoryId}-startDate`]
            : startDateOfIssue
    );
    const [endDate, setEndDate] = useState(
        filterData[`${categoryId}-endDate`]
            ? filterData[`${categoryId}-endDate`]
            : endDateOfIssue
    );

    const [loaderTimeout, setLoaderTimeout] = useState(0);
    const [isLoaderShown, setIsLoaderShown] = useState(false);

    const showLoader = () => {
        setIsLoaderShown(true);
        if (loaderTimeout) {
            clearTimeout(loaderTimeout);
        }
        setLoaderTimeout(
            setTimeout(() => {
                setIsLoaderShown(false);
            }, 500)
        );
    };

    const handleFilterChanges = (data, type) => {
        showLoader();
        if (type === "startDate") {
            setStartDate(data);
        } else if (type === "endDate") {
            setEndDate(data);
        } else if (type === "emitent") {
            if (data.currentTarget.value === "vse") {
                data = { label: "Vše", slug: "vse" };
            } else {
                data = emitentTypes.find(
                    emitent => emitent.slug === data.currentTarget.value
                );
            }
            setEmitent(data);
        } else if (type === "showInStock") {
            data = data.currentTarget.checked;
        } else if (type === "sortType") {
            data = SORT_TYPES.find(
                type => type.slug === data.currentTarget.value
            );
            setSortType(data);
        }
        setFilter({
            ...filterData,
            [`${categoryId}-${type}`]: data,
        });
    };

    const handleRangeCompleteChange = values => {
        showLoader();
        let tempTop = getValueToSet(
            "TOP",
            filterData.rangeBottom,
            values.max,
            findPriceMax(allProducts)
        );
        let tempBottom = getValueToSet(
            "BOTTOM",
            filterData.rangeTop,
            values.min,
            findPriceMin(allProducts)
        );
        setFilter({
            ...filterData,
            [`${categoryId}-rangeTop`]: tempTop,
            [`${categoryId}-rangeBottom`]: tempBottom,
        });
    };

    const filterByEmitent = products => {
        if (
            filterData[`${categoryId}-emitent`] &&
            filterData[`${categoryId}-emitent`].slug === "vse"
        ) {
            return products;
        }
        return products.filter(
            product =>
                slugify(product.emitent, { lower: true }) ===
                (filterData[`${categoryId}-emitent`] &&
                    filterData[`${categoryId}-emitent`].slug)
        );
    };

    const filterByStockState = products =>
        products.filter(product => {
            if (filterData[`${categoryId}-showInStock`]) {
                return product.stockState === IN_STOCK;
            } else {
                return product;
            }
        });

    const filterProducts = products => {
        let tempProducts;
        if (filterConfig.indexOf("emitent") !== -1) {
            tempProducts = filterByEmitent(products);
        }
        tempProducts = filterByStockState(tempProducts).filter(
            product =>
                product.priceToShow >=
                    filterData[`${categoryId}-rangeBottom`] &&
                product.priceToShow <= filterData[`${categoryId}-rangeTop`]
        );
        if (filterConfig.indexOf("date") !== -1) {
            tempProducts = tempProducts.filter(product => {
                if (product.dateOfIssue) {
                    const tempDate = new Date(product.dateOfIssue);
                    return (
                        tempDate >= filterData[`${categoryId}-startDate`] &&
                        tempDate <= filterData[`${categoryId}-endDate`]
                    );
                }
                return true;
            });
        }

        if (sortType.slug === "priceAsc") {
            return tempProducts.sort(
                (a, b) =>
                    parseInt(a.priceToShow, 10) - parseInt(b.priceToShow, 10)
            );
        } else {
            return tempProducts.sort(
                (a, b) =>
                    parseInt(b.priceToShow, 10) - parseInt(a.priceToShow, 10)
            );
        }
    };

    return (
        <>
            <Block fullWidth>
                {filterData && filterData[`${categoryId}-priceMin`] && (
                    <Filter
                        filterConfig={filterConfig}
                        emitent={emitent}
                        emitentTypes={emitentTypes}
                        priceMin={priceMin}
                        priceMax={priceMax}
                        rangeBottom={filterData[`${categoryId}-rangeBottom`]}
                        rangeTop={filterData[`${categoryId}-rangeTop`]}
                        startDate={startDate}
                        endDate={endDate}
                        showInStock={filterData[`${categoryId}-showInStock`]}
                        sortType={sortType}
                        disabled={allProducts.length === 0}
                        handleFilterChanges={handleFilterChanges}
                        handleRangeCompleteChange={handleRangeCompleteChange}
                        getSmallPart={getSmallPart}
                        getValueToSet={getValueToSet}
                    />
                )}
            </Block>
            <Block>
                {isLoaderShown && (
                    <div className={coinSpinnerStyle.wrapper}>
                        <CoinSpinner />
                    </div>
                )}
                {!isLoaderShown && filterProducts(allProducts).length !== 0 && (
                    <Products
                        products={filterProducts(allProducts)}
                        helperPageSelected={helperPageSelected}
                        setHelperPageSelected={setHelperPageSelected}
                        pageSelected={pageSelected}
                        setPageSelected={setPageSelected}
                        categorySlug={categorySlug}
                    />
                )}

                {!isLoaderShown &&
                    (allProducts.length === 0 ? (
                        <SubTitle mb={140} mt={30}>
                            V této kategorii bohužel nemáme zatím žádné
                            produkty.
                        </SubTitle>
                    ) : (
                        filterProducts(allProducts).length === 0 && (
                            <SubTitle mb={140} mt={30}>
                                Zadaným filtrům bohužel nevyhovují žádné
                                produkty
                            </SubTitle>
                        )
                    ))}
            </Block>
        </>
    );
};

export default EshopProducts;
