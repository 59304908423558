import React from "react";
import style from "./switch.module.sass";

const Switch = ({ id, name, handleChange, showInStock, disabled }) => (
    <>
        <input
            className={style.checkbox}
            id={id}
            name={name}
            type={"checkbox"}
            onChange={value => handleChange(value)}
            checked={showInStock}
            disabled={disabled}
        />
        <label className={style.label} htmlFor={id}>
            <span className={style.button} />
        </label>
    </>
);

export default Switch;
