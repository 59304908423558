import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate-next";
import Img from "gatsby-image";

import style from "./products.module.sass";
import basketWhite from "../../images/icons_new/basket.svg";
import moreIcon from "../../images/icons_new/more.svg";
import Button from "../Button";
import accounting from "accounting";
import classNames from "classnames";
import { Link } from "gatsby";
import {
    IN_STOCK,
    PRODUCTS_PER_PAGE,
    OUT_OF_STOCK,
    CM_MAX_ITEM_ORDER_QUANTITY,
} from "../../constants";
import { navigate } from "gatsby";
import { checkoutContext } from "../../context/checkoutContextProvider";
import produce from "immer";
import Modal from "../Modal/Modal";

import {
    measuringAddingProductToShoppingCart,
    measuringProductClicks,
} from "../../utils/gtm";
import Title from "../Title";

const Products = ({
    products,
    pageSelected,
    setPageSelected,
    helperPageSelected,
    setHelperPageSelected,
    categorySlug,
}) => {
    const [showModal, setShowModal] = React.useState(false);
    const [overOrderLimit, setOverOrderLimit] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const { cart, setCart } = useContext(checkoutContext);

    const addToCart = (name, itemId, price, featuredImage, vat, category) => {
        openModal();

        let product = {
            productName: name,
            productId: itemId,
            quantity: 1,
            itemPrice: price,
            featuredImage: featuredImage.childImageSharp.fluid,
            vat,
            category,
        };

        if (typeof window !== "undefined") {
            const nextState = produce(cart, draftState => {
                if (itemId in draftState) {
                    draftState[itemId].quantity = Math.min(
                        parseInt(draftState[itemId].quantity, 10) + 1,
                        CM_MAX_ITEM_ORDER_QUANTITY
                    );
                } else {
                    draftState[itemId] = product;
                }
            });
            measuringAddingProductToShoppingCart(product);
            if (
                cart[itemId] &&
                cart[itemId].quantity + 1 > CM_MAX_ITEM_ORDER_QUANTITY
            ) {
                setOverOrderLimit(true);
            }
            setCart(nextState);
        }
    };

    const productsToShow = products.filter(
        (i, index) =>
            index >= pageSelected * PRODUCTS_PER_PAGE &&
            index < helperPageSelected * PRODUCTS_PER_PAGE + PRODUCTS_PER_PAGE
    );
    const [numberOfPages, setNumberOfPages] = useState(
        Math.ceil(products.length / PRODUCTS_PER_PAGE)
    );

    const handlePageClick = ({ selected }) => {
        setPageSelected(selected);
        setHelperPageSelected(selected);
        const selectedPage = selected === 0 ? "" : `/${selected + 1}`;

        navigate(`/e-shop/${categorySlug}${selectedPage}`);
    };

    const handleLoadMore = () => {
        setHelperPageSelected(helperPageSelected + 1);

        window.history.replaceState(
            {},
            "",
            `/e-shop/${categorySlug}/${pageSelected + 2}`
        );
    };

    useEffect(() => {
        setNumberOfPages(Math.ceil(products.length / PRODUCTS_PER_PAGE));
        if (productsToShow.length === 0) {
            navigate(`/e-shop/${categorySlug}`);
        }
    }, [products]);

    return (
        <div className={style.wrapper}>
            <Title type={"h3"} color={"blueDark"} fontWeight={400}>
                Česká Mincovna
            </Title>
            <div className={style.container}>
                {productsToShow &&
                    productsToShow.map(product => {
                        const stockStateClass = classNames({
                            [style.product__stockState]: true,
                            [style.product__stockStateGreen]:
                                product.stockState === IN_STOCK,
                            [style.product__stockStateRed]:
                                product.stockState !== IN_STOCK,
                        });

                        const slug = `/e-shop/produkt/${
                            product.url.split("/")[3]
                        }`;

                        return (
                            <div key={product.itemId} className={style.product}>
                                <Link
                                    to={slug}
                                    onClick={() =>
                                        measuringProductClicks(product)
                                    }
                                    data-test="product"
                                >
                                    {product.featuredImg && (
                                        <Img
                                            fluid={
                                                product.featuredImg
                                                    .childImageSharp.fluid
                                            }
                                        />
                                    )}
                                    {!product.featuredImg && (
                                        <span>{product.productName}</span>
                                    )}
                                </Link>
                                <div className={style.product__info}>
                                    <Link
                                        to={slug}
                                        className={style.product__name}
                                        onClick={() =>
                                            measuringProductClicks(product)
                                        }
                                    >
                                        {product.productName}
                                    </Link>
                                    <div
                                        className={style.product__landscapeBox}
                                    >
                                        <p className={style.product__price}>
                                            {accounting.formatNumber(
                                                product.priceToShow,
                                                0,
                                                " ",
                                                ","
                                            )}
                                            <span>Kč</span>
                                        </p>
                                        <span className={stockStateClass}>
                                            {product.stockState}
                                        </span>
                                    </div>

                                    <div className={style.product__buttons}>
                                        <Button
                                            onClick={() =>
                                                measuringProductClicks(product)
                                            }
                                            uri={slug}
                                            type={"link"}
                                            color={"outline-gold-dark"}
                                        >
                                            Více informací
                                        </Button>

                                        {product.stockState !==
                                            OUT_OF_STOCK && (
                                            <Button
                                                onClick={() =>
                                                    addToCart(
                                                        product.productName,
                                                        product.itemId,
                                                        product.priceToShow,
                                                        product.featuredImg,
                                                        product.vat,
                                                        product.category
                                                    )
                                                }
                                                color={"gold"}
                                                width={"buy"}
                                            >
                                                <img src={basketWhite} />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className={style.paginationWrapper}>
                <ReactPaginate
                    pageCount={numberOfPages}
                    initialPage={pageSelected}
                    forcePage={helperPageSelected}
                    disableInitialCallback={true}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={1}
                    previousLabel={<img src={moreIcon} />}
                    nextLabel={<img src={moreIcon} />}
                    breakLabel={"..."}
                    breakClassName={style.ellipsis}
                    containerClassName={style.pagination}
                    activeClassName={style.active}
                />

                {products.length >
                    helperPageSelected * PRODUCTS_PER_PAGE +
                        PRODUCTS_PER_PAGE && (
                    <Button color={"gold"} onClick={handleLoadMore}>
                        Načíst další
                    </Button>
                )}
            </div>

            <Modal
                isOpen={showModal}
                onRequestClose={() => closeModal()}
                closeTimeoutMS={200}
                overOrderLimit={overOrderLimit}
            />
        </div>
    );
};

export default Products;
