import React, { useState } from "react";
import NumberFormat from "react-number-format";
import InputRange from "react-input-range";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import slugify from "slugify";

import "react-datepicker/dist/react-datepicker.css";
import "react-input-range/lib/css/index.css";
import style from "./filter.module.sass";
import formStyle from "../Eshop/form.module.sass";

import Switch from "../Switch";
import ProductSorter from "../ProductSorter";

const Filter = ({
    filterConfig,
    emitent,
    emitentTypes,
    priceMin,
    priceMax,
    rangeBottom,
    rangeTop,
    startDate,
    endDate,
    showInStock,
    sortType,
    disabled,
    handleFilterChanges,
    handleRangeCompleteChange,
    getSmallPart,
    getValueToSet,
}) => {
    const monthsCs = [
        "leden",
        "únor",
        "březen",
        "duben",
        "květen",
        "červen",
        "červenec",
        "srpen",
        "září",
        "říjen",
        "listopad",
        "prosinec",
    ];

    const locale = {
        localize: {
            month: n => monthsCs[n],
        },
        formatLong: {},
    };

    const [tempRangeBottom, setTempRangeBottom] = useState(rangeBottom);
    const [tempRangeTop, setTempRangeTop] = useState(rangeTop);
    const [inputRangeBottom, setInputRangeBottom] = useState(rangeBottom);
    const [inputRangeTop, setInputRangeTop] = useState(rangeTop);

    const [filterOpen, setFilterOpen] = useState(false);

    const [inputChangeTimeout, setInputChangeTimeout] = useState(0);

    const handleFilterButtonClick = e => {
        e.preventDefault();
        setFilterOpen(!filterOpen);
    };

    const handleRangeChange = (values, ignoreOffset) => {
        let tempTop = getValueToSet(
            "TOP",
            rangeBottom,
            values.max,
            priceMax,
            ignoreOffset
        );
        let tempBottom = getValueToSet(
            "BOTTOM",
            rangeTop,
            values.min,
            priceMin,
            ignoreOffset
        );

        setTempRangeBottom(tempBottom);
        setTempRangeTop(tempTop);
        setInputRangeBottom(tempBottom);
        setInputRangeTop(tempTop);
    };

    const handleInputChangeYay = e => {
        const target = e.currentTarget;
        const name = target.name;
        let value = parseInt(
            e.currentTarget.value.replace(" Kč", "").replace(" ", "")
        );

        if (name === "rangeBottom") {
            setInputRangeBottom(value);
        } else if (name === "rangeTop") {
            setInputRangeTop(value);
        }
        if (inputChangeTimeout) {
            clearTimeout(inputChangeTimeout);
        }

        setInputChangeTimeout(
            setTimeout(() => {
                let tempObject = {};
                if (name === "rangeTop") {
                    if (value <= rangeBottom) {
                        value = rangeBottom + getSmallPart();
                        setInputRangeTop(value);
                        tempObject = {
                            min: tempRangeBottom,
                            max: value,
                        };
                        handleRangeChange(tempObject, true);
                    } else if (value <= priceMax) {
                        tempObject = {
                            min: tempRangeBottom,
                            max: value,
                        };
                        handleRangeChange(tempObject, true);
                    }
                    setTempRangeTop(value);
                } else {
                    if (value >= rangeTop) {
                        value = rangeTop - getSmallPart();
                        setInputRangeBottom(value);
                        tempObject = {
                            min: value,
                            max: tempRangeTop,
                        };
                        handleRangeChange(tempObject, true);
                    } else if (value >= priceMin) {
                        tempObject = {
                            min: value,
                            max: tempRangeTop,
                        };
                        handleRangeChange(tempObject, true);
                    }
                    setTempRangeBottom(value);
                }
                handleRangeCompleteChange(tempObject);
            }, 2000)
        );
    };

    return (
        <>
            <div
                className={classNames({
                    [style.wrapper]: true,
                    [style.wrapperNoBackground]: filterOpen,
                })}
            >
                <button
                    className={style.filterButton}
                    onClick={e => handleFilterButtonClick(e)}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.375 12.8574V10.6426H18.875V12.8574H5.375ZM2 5H22.25V7.26758H2V5ZM9.85742 18.5V16.2324H14.3926V18.5H9.85742Z"
                            fill="#00212F"
                        />
                    </svg>
                    Filtrovat
                </button>
                <form
                    className={classNames({
                        [style.form]: true,
                        [style.formOpen]: !filterOpen,
                    })}
                >
                    {filterConfig.indexOf("emitent") !== -1 && (
                        <div
                            className={classNames({
                                [style.fieldset]: true,
                                [style.supplier]: true,
                            })}
                        >
                            <label
                                className={formStyle.label}
                                htmlFor={"emitent"}
                            >
                                <span>Emitent</span>
                            </label>
                            <select
                                className={formStyle.control}
                                id={"emitent"}
                                name={"emitent"}
                                onChange={event =>
                                    handleFilterChanges(event, "emitent")
                                }
                                disabled={disabled}
                                value={emitent.slug}
                            >
                                <option value={"vse"}>Vše</option>
                                {emitentTypes.map(({ label, slug }) => (
                                    <option key={slug} value={slug}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {filterConfig.indexOf("price") !== -1 &&
                        priceMin !== priceMax && (
                            <div className={style.priceFilterContainer}>
                                <div
                                    className={classNames({
                                        [style.fieldset]: true,
                                        [style.priceMin]: true,
                                    })}
                                >
                                    <label
                                        className={formStyle.label}
                                        htmlFor={slugify("Cena od")}
                                    >
                                        <span>Cena od</span>
                                    </label>
                                    <div className={formStyle.control}>
                                        <NumberFormat
                                            isAllowed={values => {
                                                const {
                                                    formattedValue,
                                                    floatValue,
                                                } = values;
                                                return (
                                                    formattedValue === "" ||
                                                    floatValue >= 0
                                                );
                                            }}
                                            name={"rangeBottom"}
                                            type={"text"}
                                            defaultValue={rangeBottom}
                                            value={inputRangeBottom}
                                            onChange={e =>
                                                handleInputChangeYay(e)
                                            }
                                            thousandSeparator={" "}
                                            suffix={" Kč"}
                                            id={slugify("Cena od")}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={classNames({
                                        [style.fieldset]: true,
                                        [style.priceSlider]: true,
                                    })}
                                >
                                    <div className={style.range}>
                                        <InputRange
                                            minValue={priceMin ? priceMin : 0}
                                            maxValue={
                                                priceMax ? priceMax : 999999999
                                            }
                                            value={{
                                                min: tempRangeBottom,
                                                max: tempRangeTop,
                                            }}
                                            step={100}
                                            onChange={handleRangeChange}
                                            onChangeComplete={
                                                handleRangeCompleteChange
                                            }
                                            allowSameValues={true}
                                            disabled={disabled}
                                            /*classNames={{
												activeTrack: style.activeTrack,
												disabledInputRange: style.disabled,
												inputRange: style.inputRange,
												labelContainer: style.labelContainer,
												// maxLabel: string,
												// minLabel: string,
												slider: style.slider,
												sliderContainer: style.sliderContainer,
												track: style.track,
												valueLabel: style.valueLabel
											}}*/
                                        />
                                    </div>
                                </div>
                                <div
                                    className={classNames({
                                        [style.fieldset]: true,
                                        [style.priceMax]: true,
                                    })}
                                >
                                    <label
                                        className={formStyle.label}
                                        htmlFor={slugify("Cena do")}
                                    >
                                        <span>Cena do</span>
                                    </label>
                                    <div className={formStyle.control}>
                                        <NumberFormat
                                            isAllowed={values => {
                                                const {
                                                    formattedValue,
                                                    floatValue,
                                                } = values;
                                                return (
                                                    formattedValue === "" ||
                                                    floatValue <= 999999999
                                                );
                                            }}
                                            name={"rangeTop"}
                                            type={"text"}
                                            defaultValue={rangeTop}
                                            value={inputRangeTop}
                                            onChange={e =>
                                                handleInputChangeYay(e)
                                            }
                                            thousandSeparator={" "}
                                            suffix={" Kč"}
                                            id={slugify("Cena do")}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    {filterConfig.indexOf("date") !== -1 && (
                        <div className={style.dateFilterContainer}>
                            <div
                                className={classNames({
                                    [style.fieldset]: true,
                                    [style.datePicker]: true,
                                    [style.datePickerStart]: true,
                                })}
                            >
                                <label
                                    className={formStyle.label}
                                    htmlFor={slugify("Datum emise od")}
                                >
                                    <span>Datum emise</span>
                                </label>
                                <div className={formStyle.control}>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={date =>
                                            handleFilterChanges(
                                                date,
                                                "startDate"
                                            )
                                        }
                                        locale={locale}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="MMMM yyyy"
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                    />
                                </div>
                            </div>
                            <div
                                className={classNames({
                                    [style.fieldset]: true,
                                    [style.datePicker]: true,
                                    [style.datePickerMiddle]: true,
                                })}
                            >
                                až
                            </div>
                            <div
                                className={classNames({
                                    [style.fieldset]: true,
                                    [style.datePicker]: true,
                                    [style.datePickerEnd]: true,
                                })}
                            >
                                <label
                                    className={formStyle.label}
                                    htmlFor={slugify("Datum emise do")}
                                >
                                    <span>Do</span>
                                </label>
                                <div className={formStyle.control}>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={date =>
                                            handleFilterChanges(date, "endDate")
                                        }
                                        locale={locale}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="MMMM yyyy"
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                        showMonthDropdown
                                        showYearDropdown
                                        calendarClassName={
                                            style.datePickerPopper
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </form>
            </div>
            <div className={style.bottomWrapper}>
                <div className={style.switchWrapper}>
                    <Switch
                        id={"toggle-in-stock"}
                        name={"show-in-stock"}
                        handleChange={event =>
                            handleFilterChanges(event, "showInStock")
                        }
                        showInStock={showInStock}
                        disabled={disabled}
                    />
                    <label
                        className={style.switchLabel}
                        htmlFor={"toggle-in-stock"}
                    >
                        Pouze skladem
                    </label>
                </div>

                <ProductSorter
                    selectedSorter={sortType}
                    handleChange={event =>
                        handleFilterChanges(event, "sortType")
                    }
                    disabled={disabled}
                />
            </div>
        </>
    );
};

export default Filter;
